
body {
  width: 100%;
}

div {
  display: flex;
}

.wrapper {
  width: 100%;
  margin:auto;
  flex-direction: column;
}

.metacontainer {
  flex-direction: column;
  display:flex;
}

@media only screen and (min-width: 931px) {
  .metacontainer {
    flex-direction: row-reverse;
    display:flex;
    align-items: stretch;
    flex-wrap: wrap;
  }

  .section {
    min-width: 40vw;
    flex-basis: 40vw;
  }
  .image-section {
    max-height: 1024px;
    flex-grow: 4;
    order: 2;
  }
  .text-section {
    flex-grow: 1;
    order: 3;
  }
  .footer {
    order: 4;
  }
  img.big-image {
    top: 0%;
  }
}

@media only screen and (max-width: 930px) {
  .text-section {
    height: 100%;
  }
  div div.center-links {
    top: calc(-10vw - 100px);
  }
}

.logospin {
  float: right;
  max-width: 150%;
}

.section {
  /* max-height: 1400px; */
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-section {
  background-position: center;
  background-repeat: no-repeat;
}

.header {
  flex-align: right;
  align-items: flex-end;
  order: 0;
}

.header img {
  margin-bottom: -2rem;
}

.big-image {
  float: center;
  top:50%;
  position: relative;
  margin-top: -40%;
  max-width: 2200px;
  width: 100%;
  min-width:480px;
}

div.center-links {
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  top: -140px;
}

div.center-links a {
  padding: 10px;
  size: 4em;
  margin: 5px;
  aspect-ration: 1;
}

div.center-links svg {
  height: calc(2.5em - 8.12px);
  width: 2.5em;
  top: 4.06px;
  position: relative;

}

div.center-links a:hover {
  text-decoration: none;

}
@media (max-width: 480px){
  .big-image {
    /* left:calc((-480px + 100vw)/2 )!important; */
  }
}

div.about div.center-links a {
  padding: 10px;
}

.cta-text {
  padding: 3em;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

div.cta-text {
  font-size: 2rem;
}

input {
  margin: 3px;
}

.footer {
  justify-content: center;
}

.grecaptcha-badge {
    visibility: hidden;
}
